:root {
  --primary: white;
}
.btn {
  font-size: 12pt;
}
.btn--outline {
  background-color: transparent;
  /* background: linear-gradient(
    225deg,
    hsla(344, 63%, 49%, 1) 0%,
    hsla(309, 87%, 42%, 1) 39%,
    hsla(10, 69%, 47%, 1) 72%,
    hsla(335, 69%, 47%, 1) 100%
  ); */
  border-radius: 25px;
  color: white;
  font-family: "Ubuntu Mono", monospace;
  padding: 20px;
  border: 2px solid var(--primary);
  transition: all 0.3s ease-out;
  width: 100%;
  font-size: 14pt;
}

.btn:hover,
.btn:hover {
  transition: all 0.3s ease-in-out;
  background: white;
  color: black;
  /* background: #e20dc9; */
  /* --primary: #e20dc9; */
  /* color: white; */
}
