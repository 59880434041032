.accordion-section {
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  /* position: center; */
  min-height: 100vh;
  background: black;
  /* padding-bottom: 1000px; */
}

.container {
  position: absolute;
  top: 30%;
  width: 100%;
  /* background */
}

.wrap {
  background: black;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 80%; */
  width: 100%;
  /* text-align: center; */
  cursor: pointer;
  padding-left: 20vw;
  padding-right: 20vw;
  font-size: 30px;
}

.faq-question {
  padding: 1rem;
  display: inline-block;
  width: 75%;
}

.faq-span {
  display: inline-block;
}
/* p {
  background-color: black;
  color: white;
} */
.p1 {
  background-color: black;
  color: #ff26e6;
}
.dropdown {
  background: white;
  margin-left: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 50%;
  justify-content: center;
  align-items: left;
  max-width: 100%;
}

@media only screen and (max-width: 600px) {
  .accordion-section {
    padding-bottom: 10px;
  }
  .container {
    top: 15%;
  }

  .wrap {
    padding-left: 2vw;
    padding-right: 2vw;
  }

  .p1 {
    width: 100%;
  }

  .dropdown {
    margin-left: 10vw;
    width: 80vw;
  }
}
