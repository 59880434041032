.footer-container {
  font-family: "Ubuntu Mono", monospace;
  background-color: black;
  /* background: rgb(12, 2, 17);
  background: linear-gradient(
    93deg,
    rgba(12, 2, 17, 1) 49%,
    rgba(15, 9, 18, 1) 100%
  ); */
  padding: 0.5rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* 
.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
} */
.footer--title {
  margin-bottom: 14px;
  font-size: 18px;
  color: #fff;
}
.footer--text {
  margin-bottom: 24px;
  font-size: 12px;
  color: #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  text-align: center;
  justify-content: center;
}

.footer-input::placeholder {
  color: #2e2837;
}

.social-icon-link {
  color: #fff;
  font-size: 35px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-logo {
  color: #fff;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
}
