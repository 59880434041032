.home,
.meetings,
.faq,
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
  font-family: "Ubuntu Mono", monospace;
}
